import * as _forEach2 from "for-each";
var _forEach = "default" in _forEach2 ? _forEach2.default : _forEach2;
import * as _availableTypedArrays2 from "available-typed-arrays";
var _availableTypedArrays = "default" in _availableTypedArrays2 ? _availableTypedArrays2.default : _availableTypedArrays2;
import * as _callBind2 from "call-bind";
var _callBind = "default" in _callBind2 ? _callBind2.default : _callBind2;
import * as _callBound2 from "call-bind/callBound";
var _callBound = "default" in _callBound2 ? _callBound2.default : _callBound2;
import * as _gopd2 from "gopd";
var _gopd = "default" in _gopd2 ? _gopd2.default : _gopd2;
import * as _shams2 from "has-tostringtag/shams";
var _shams = "default" in _shams2 ? _shams2.default : _shams2;
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
var forEach = _forEach;
var availableTypedArrays = _availableTypedArrays;
var callBind = _callBind;
var callBound = _callBound;
var gOPD = _gopd;
var $toString = callBound("Object.prototype.toString");
var hasToStringTag = _shams();
var g = typeof globalThis === "undefined" ? _global : globalThis;
var typedArrays = availableTypedArrays();
var $slice = callBound("String.prototype.slice");
var getPrototypeOf = Object.getPrototypeOf; // require('getprototypeof');

var $indexOf = callBound("Array.prototype.indexOf", true) || function indexOf(array, value) {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i] === value) {
      return i;
    }
  }
  return -1;
};
var cache = {
  __proto__: null
};
if (hasToStringTag && gOPD && getPrototypeOf) {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    if (Symbol.toStringTag in arr) {
      var proto = getPrototypeOf(arr);
      var descriptor = gOPD(proto, Symbol.toStringTag);
      if (!descriptor) {
        var superProto = getPrototypeOf(proto);
        descriptor = gOPD(superProto, Symbol.toStringTag);
      }
      cache["$" + typedArray] = callBind(descriptor.get);
    }
  });
} else {
  forEach(typedArrays, function (typedArray) {
    var arr = new g[typedArray]();
    var fn = arr.slice || arr.set;
    if (fn) {
      cache["$" + typedArray] = callBind(fn);
    }
  });
}
var tryTypedArrays = function tryAllTypedArrays(value) {
  var found = false;
  forEach(cache, function (getter, typedArray) {
    if (!found) {
      try {
        if ("$" + getter(value) === typedArray) {
          found = $slice(typedArray, 1);
        }
      } catch (e) {/**/}
    }
  });
  return found;
};
var trySlices = function tryAllSlices(value) {
  var found = false;
  forEach(cache, function (getter, name) {
    if (!found) {
      try {
        getter(value);
        found = $slice(name, 1);
      } catch (e) {/**/}
    }
  });
  return found;
};
exports = function whichTypedArray(value) {
  if (!value || typeof value !== "object") {
    return false;
  }
  if (!hasToStringTag) {
    var tag = $slice($toString(value), 8, -1);
    if ($indexOf(typedArrays, tag) > -1) {
      return tag;
    }
    if (tag !== "Object") {
      return false;
    }
    // node < 0.6 hits here on real Typed Arrays
    return trySlices(value);
  }
  if (!gOPD) {
    return null;
  } // unknown engine
  return tryTypedArrays(value);
};
export default exports;